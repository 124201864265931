const acsDirectoriesRoutes = [
  {
    path: '/acs-directories/acs-premises',
    name: 'acsPremises',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'CКУД', disabled: true },
        { text: 'Помещения', disabled: true },
      ],
    },
    component: () => import('@/components/AcsDirectories/AcsPremises.vue'),
  },
  {
    path: '/acs-directories/acs-controllers',
    name: 'acsControllers',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'CКУД', disabled: true },
        { text: 'Контроллеры', disabled: true },
      ],
    },
    component: () => import('@/components/AcsDirectories/AcsControllers.vue'),
  },
  {
    path: '/acs-directories/acs-keys',
    name: 'acsKeys',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'CКУД', disabled: true },
        { text: 'Ключи', disabled: true },
      ],
    },
    component: () => import('@/components/AcsDirectories/AcsKeys.vue'),
  },
];

export default acsDirectoriesRoutes;
