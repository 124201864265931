/* eslint-disable import/prefer-default-export */
export const devicesRoutes = [
  {
    path: '/devices/controllers',
    name: 'Сontrollers',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Устройства', disabled: true },
        { text: 'Контроллеры', disabled: true },
      ],
    },
    component: () => import('@/components/Devices/Controllers/ControllersObjects.vue'),
  },
  {
    path: '/devices/devices-data',
    name: 'DevicesData',
    meta: {
      layout: 'Main',
      auth: true,
      breadcrumb: [
        { text: 'Устройства', disabled: true },

        { text: 'Приборы учета (ПУ)', disabled: true },
      ],
    },
    component: () => import('@/components/Devices/DevicesData/DevicesObjects.vue'),
  },
];
