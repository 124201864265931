import { objectsRoutes } from './objectsRoutes';
import { clientsRoutes } from './clientsRoutes';
import { devicesRoutes } from './devicesRoutes';
import { billingRoutes } from './billingRoutes';
import { directoriesRoutes } from './directoriesRoutes';
import { logsRoutes } from './logsRoutes';
import { settingsRoutes } from './settingsRoutes';
import { reportsRoutes } from './reportsRoutes';
import acsDirectoriesRoutes from './acsDirectoriesRoutes';

const appRoutes = {
  objects: objectsRoutes,
  clients: clientsRoutes,
  devices: devicesRoutes,
  billing: billingRoutes,
  directories: directoriesRoutes,
  logs: logsRoutes,
  settings: settingsRoutes,
  reports: reportsRoutes,
  acsDirectories: acsDirectoriesRoutes,
};

export default appRoutes;
