// eslint-disable-next-line import/prefer-default-export
export function getSortedOptions(tableOptions, sortingMap) {
  const { sortBy, sortDesc } = tableOptions;
  let currentSortBy = sortBy;
  let currentSortDesc = sortDesc;

  if (sortingMap.fields.includes(sortBy[0])) {
    currentSortBy = sortingMap[sortBy[0]];
    const count = sortingMap[sortBy[0]].length;
    currentSortDesc = Array(count).fill(sortDesc[0]);
  }

  return { sortBy: currentSortBy, sortDesc: currentSortDesc };
}

export const deviceMultiplierMap = {
  '1.0.12.7.0.255': {
    read: 1,
    store: 1,
  },
  '1.0.14.7.0.255': {
    read: 1,
    store: 0.01,
  },
  '1.0.11.7.0.255': {
    read: 1,
    store: 1,
  },
  '1.0.1.8.0.255': {
    read: 1,
    store: 1,
  },
};
