/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const ticketsStore = {
  namespaced: true,
  state: {
    groups: [], // groupsCount??
  },

  mutations: {
    SET_GROUPS(state, payload) {
      state.groups = payload.data;
    },
  },
  actions: {
    async getGroups({ commit }, payload) {
      try {
        const response = await req.post('ticketsGroups', payload);
        commit('SET_GROUPS', response.data);
      } catch (e) {
        console.log(e);
      }
    },
  },
};

export default ticketsStore;
