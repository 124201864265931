/* eslint-disable import/no-cycle */
import req from '@/plugins/requests';

const personContactsStore = {
  namespaced: true,
  state: {
    list: [],
    total: 0,
  },
  mutations: {
    SET_PERSON_CONTACTS(state, payload) {
      state.list = payload;
      state.total = payload.total;
    },
  },
  actions: {
    async getPersonContacts(context, payload) {
      const response = await req.post('personContacts', payload);
      context.commit('SET_PERSON_CONTACTS', response.data.data);
    },

    async updatePersonContacts(context, payload) {
      await req.post('personContactsSave', { obj: { ...payload } });
    },

    async removePersonContacts(context, payload) {
      await req.post('personContactsDelete', payload);
    },
  },
};

export default personContactsStore;
